<script lang="ts">import { geolocationRequesterStatus, interactionVerb } from "./store";
import ignoreError from "./utilities/ignoreError";
import setAreaCenterUsingWebGeolocationApi from "./utilities/setAreaCenterUsingWebGeolocationApi";
import trackEvent from "./utilities/trackEvent";

const prompt = async () => {
  geolocationRequesterStatus.set('prompted');

  try {
    await setAreaCenterUsingWebGeolocationApi();
    geolocationRequesterStatus.set(null);
    ignoreError(() => localStorage.setItem("lastKnownWebGeolocationPermissionState", 'granted'));
    trackEvent({
      name: "web-geolocation-prompt-flow-complete",
      title: "Web geolocation prompt flow complete"
    });
  } catch (e) {
    if (!(e instanceof GeolocationPositionError)) {
      ignoreError(() => localStorage.removeItem("lastKnownWebGeolocationPermissionState"));
      throw e;
    }

    ignoreError(() => localStorage.setItem("lastKnownWebGeolocationPermissionState", 'denied'));
    geolocationRequesterStatus.set(null);
    trackEvent({
      name: "web-geolocation-prompt-rejected",
      title: "Web geolocation prompt rejected"
    });
  }
};

const cancel = async () => {
  geolocationRequesterStatus.set(null);
  trackEvent({
    name: `geolocation-requester-cancelled--${$geolocationRequesterStatus}`,
    title: `GeolocationRequester cancelled (status was ${$geolocationRequesterStatus})`
  });
};

const retry = () => {
  ignoreError(() => localStorage.removeItem("lastKnownWebGeolocationPermissionState")); // @ts-ignore

  window.location = window.location.href;
};</script>

<div class="geolocation-requester {$geolocationRequesterStatus ? `geolocation-requester--${$geolocationRequesterStatus}` : ''}">
  {#if $geolocationRequesterStatus !== null}
    <div class="geolocation-requester__inner">

      {#if $geolocationRequesterStatus === 'pre-prompt'}

        <h2 class="geolocation-requester__heading">Move circle to your current location?</h2>

        <div class="geolocation-requester__instructions">
          <p><i>MapX</i> needs permission to access your GPS location in this web browser.</p>
          <p>To proceed:</p>
          <ol>
            <li>{$interactionVerb} "Continue".</li>
            <li>{$interactionVerb} "Allow" when prompted by your browser.</li>
          </ol>
        </div>
        
        <div class="button-group">
          <button
            class="button--primary"
            on:click={prompt}>Continue</button>
          <button
            on:click={cancel}>Cancel</button>
        </div>

      {:else if $geolocationRequesterStatus === 'prompted'}

        <p>{$interactionVerb} "Allow"</p>

      {:else if $geolocationRequesterStatus === 'denied'}

        <h2 class="geolocation-requester__heading">Move circle to your current location?</h2>

        <div class="geolocation-requester__instructions">
          <p><i>MapX</i> needs permission to access your GPS location in this web browser.</p>
          <p>You previously blocked this. To proceed:<p>
          <ol>
            <li>Reset all permissions for mapx.arvrtise.com in your web browser settings.</li>
            <li>Click "Retry" below.</li>
          </ol>
        </div>

        <div class="button-group">
          <button
            class="button--primary"
            on:click={retry}>Retry</button>
          <button
            on:click={cancel}>Cancel</button>
        </div>

      {/if}

    </div>
  {/if}
</div>

<style>
  .geolocation-requester {
    position: fixed;
    inset: 0;
    background: rgba(0,0,0,0.95);
    color: white;
    z-index: 9999999;

    display: none;
    align-items: center;
    justify-items: center;
    justify-content: center;
  }

  .geolocation-requester--denied,
  .geolocation-requester--pre-prompt,
  .geolocation-requester--prompted {
    display: flex;
  }

  .geolocation-requester__inner {
    max-width: 600px;
    margin: 20px;
  }

  .geolocation-requester__heading {
    font-size: 2em;
  }

  .geolocation-requester__instructions {
    line-height: 1.5;
    margin: 40px 0;
  }

  .geolocation-requester__instructions > * + * {
    margin-top: 20px;
  }

  .geolocation-requester ol {
    padding-left: 20px;
  }

  .geolocation-requester li {
    list-style: decimal;
  }
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNvdXJjZS9zb3VyY2UvR2VvbG9jYXRpb25SZXF1ZXN0ZXIuc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7RUFDRTtJQUNFLGVBQWU7SUFDZixRQUFRO0lBQ1IsNEJBQTRCO0lBQzVCLFlBQVk7SUFDWixnQkFBZ0I7O0lBRWhCLGFBQWE7SUFDYixtQkFBbUI7SUFDbkIscUJBQXFCO0lBQ3JCLHVCQUF1QjtFQUN6Qjs7RUFFQTs7O0lBR0UsYUFBYTtFQUNmOztFQUVBO0lBQ0UsZ0JBQWdCO0lBQ2hCLFlBQVk7RUFDZDs7RUFFQTtJQUNFLGNBQWM7RUFDaEI7O0VBRUE7SUFDRSxnQkFBZ0I7SUFDaEIsY0FBYztFQUNoQjs7RUFFQTtJQUNFLGdCQUFnQjtFQUNsQjs7RUFFQTtJQUNFLGtCQUFrQjtFQUNwQjs7RUFFQTtJQUNFLG1CQUFtQjtFQUNyQiIsImZpbGUiOiJzb3VyY2UvR2VvbG9jYXRpb25SZXF1ZXN0ZXIuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG4gIC5nZW9sb2NhdGlvbi1yZXF1ZXN0ZXIge1xuICAgIHBvc2l0aW9uOiBmaXhlZDtcbiAgICBpbnNldDogMDtcbiAgICBiYWNrZ3JvdW5kOiByZ2JhKDAsMCwwLDAuOTUpO1xuICAgIGNvbG9yOiB3aGl0ZTtcbiAgICB6LWluZGV4OiA5OTk5OTk5O1xuXG4gICAgZGlzcGxheTogbm9uZTtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgIGp1c3RpZnktaXRlbXM6IGNlbnRlcjtcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgfVxuXG4gIC5nZW9sb2NhdGlvbi1yZXF1ZXN0ZXItLWRlbmllZCxcbiAgLmdlb2xvY2F0aW9uLXJlcXVlc3Rlci0tcHJlLXByb21wdCxcbiAgLmdlb2xvY2F0aW9uLXJlcXVlc3Rlci0tcHJvbXB0ZWQge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gIH1cblxuICAuZ2VvbG9jYXRpb24tcmVxdWVzdGVyX19pbm5lciB7XG4gICAgbWF4LXdpZHRoOiA2MDBweDtcbiAgICBtYXJnaW46IDIwcHg7XG4gIH1cblxuICAuZ2VvbG9jYXRpb24tcmVxdWVzdGVyX19oZWFkaW5nIHtcbiAgICBmb250LXNpemU6IDJlbTtcbiAgfVxuXG4gIC5nZW9sb2NhdGlvbi1yZXF1ZXN0ZXJfX2luc3RydWN0aW9ucyB7XG4gICAgbGluZS1oZWlnaHQ6IDEuNTtcbiAgICBtYXJnaW46IDQwcHggMDtcbiAgfVxuXG4gIC5nZW9sb2NhdGlvbi1yZXF1ZXN0ZXJfX2luc3RydWN0aW9ucyA+ICogKyAqIHtcbiAgICBtYXJnaW4tdG9wOiAyMHB4O1xuICB9XG5cbiAgLmdlb2xvY2F0aW9uLXJlcXVlc3RlciBvbCB7XG4gICAgcGFkZGluZy1sZWZ0OiAyMHB4O1xuICB9XG5cbiAgLmdlb2xvY2F0aW9uLXJlcXVlc3RlciBsaSB7XG4gICAgbGlzdC1zdHlsZTogZGVjaW1hbDtcbiAgfVxuIl19 */</style>